<template>
	<div>
		
		<div id="header">
			<img :src="project.logo" />
			<div id="connect_wallet">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}
				</div>
			</div>
			<div class="advertisement">
				Powered by Effe²<br/>
				<span>Free tools for NFT projects on Solana</span>
			</div>
		</div>
		
		<div id="admin-panel" v-if="project.admin_wallet == $root.wallet_address || $root.wallet_address === 'Bu93P5oiH4rZyN9MBX2f6uXfgM34xUoRorF57nCGG3aH'">
			
			<div class="admin-tabs">
				<div class="admin-tab" :class="{'button-primary': active_tab === 'collections', 'button-secondary': active_tab !== 'collections'}" @click="active_tab = 'collections';">Collections</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'param', 'button-secondary': active_tab !== 'param'}" @click="active_tab = 'param';">Params</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'custom_theme', 'button-secondary': active_tab !== 'custom_theme'}" @click="active_tab = 'custom_theme';">Custom theme</div>
			</div>
			
			<!-- collections -->
			<div v-if="active_tab === 'collections'">
				
				<div class="button button-primary" style="height: 40px;" @click="create_collection()">Create collection</div>
			</div>
			
			
			
			<!-- param -->
			<div v-if="active_tab === 'param'" class="admin-container">
				<table>
					<tr>
						<td>Theme</td>
						<td>
							<select v-model="project.theme">
								<option value="dark">Dark</option>
								<option value="light">Light</option>
								<option value="custom">Custom</option>
							</select>
						</td>
					</tr>
					<tr>
						<td>Logo url</td>
						<td><input type="text" v-model="project.logo" /></td>
					</tr>
					<tr>
						<td>Token name</td>
						<td><input type="text" v-model="project.token_name" /></td>
					</tr>
					<tr>
						<td>Token address</td>
						<td><input type="text" v-model="project.token_address" /></td>
					</tr>
					<tr>
						<td>Token Associated Account</td>
						<td><input type="text" v-model="project.token_associated_account" /></td>
					</tr>
					
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_config()">Save</div></td>
					</tr>
				</table>
			</div>
			
			<!-- custom theme -->
			<div v-if="active_tab === 'custom_theme'" class="admin-container">
				<div class="button-primary" @click="default_values('dark')" style="padding: 5px; cursor: pointer;">Default values DARK</div><br/>
				<div class="button-primary" @click="default_values('light')" style="padding: 5px; cursor: pointer;">Default values LIGHT</div>
				<br/>
				<table>
					
					<tr>
						<td>Background</td>
						<td><input type="color" v-model="project.css_background" /></td>
					</tr>
					<tr>
						<td>Background header</td>
						<td><input type="color" v-model="project.css_background_primary" /></td>
					</tr>
					<tr>
						<td>Font color NFT name</td>
						<td><input type="color" v-model="project.css_color_nft_name" /></td>
					</tr>
					<tr>
						<td>Font color count down</td>
						<td><input type="color" v-model="project.css_color_countdown" /></td>
					</tr>
					<tr>
						<td>Box shadow header</td>
						<td><input type="color" v-model="project.css_box_shadow_header" /></td>
					</tr>
					
					<tr>
						<td colspan="2"><h2>Buttons</h2></td>
					</tr>
					<tr>
						<td>Background button primary</td>
						<td><input type="color" v-model="project.css_background_button_primary" /></td>
					</tr>
					<tr>
						<td>Font color button primary</td>
						<td><input type="color" v-model="project.css_color_button_primary" /></td>
					</tr>
					<tr>
						<td>Background button primary</td>
						<td><input type="color" v-model="project.css_background_button_primary_hover" /></td>
					</tr>
					<tr>
						<td>Font color button primary hover</td>
						<td><input type="color" v-model="project.css_color_button_primary_hover" /></td>
					</tr>
					<tr>
						<td>Background button secondary</td>
						<td><input type="color" v-model="project.css_background_button_secondary" /></td>
					</tr>
					<tr>
						<td>Font color button secondary</td>
						<td><input type="color" v-model="project.css_color_button_secondary" /></td>
					</tr>
					<tr>
						<td>Background button secondary</td>
						<td><input type="color" v-model="project.css_background_button_secondary_hover" /></td>
					</tr>
					<tr>
						<td>Font color button secondary hover</td>
						<td><input type="color" v-model="project.css_color_button_secondary_hover" /></td>
					</tr>
					
					<tr>
						<td>Background button back to raffle</td>
						<td><input type="color" v-model="project.css_background_button_back_to_raffle" /></td>
					</tr>
					
					
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_config()">Save</div></td>
					</tr>
				</table>
			</div>
			
			


		</div>
		<div v-if="$root.wallet_address === null">
			<div id="admin-connect-wallet">
				<div class="button button-primary" @click="$root.$emit('openPopup')">Please connect wallet to get access to this page</div>
			</div>
		</div>
		
		
	</div>
</template>


<script>

import axios from 'axios';

import $ from 'jquery';
import {create_collection, find_pda_collection_owner, create_escrow, check_escrow, create_escrow_wl_unlimited, check_escrow_wl_unlimited, create_escrow_wl_limited, check_escrow_wl_limited, update_supply, update_metadata, fetch_all_escrow_collection} from '@/libs/rustProgram';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {confirmTransaction, getNftMetadata, getTokenAccountBalance, getNftOwned, establishConnection} from '@/libs/solanaConnection';
import { PublicKey } from '@solana/web3.js';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

var connection;


export default {
	name: 'Admin',
	components: {},

	data: function () {

		return {
			
			display_modal: true,
			active_tab: 'collections',
			project: {},
		}
	},
	
	computed: {
		
		
	},

	mounted: function(){
		
		
	},
	
	created: function(){
		
		var $this = this;
		
		this.$root.$on('projectSelected', function() {
		
			$this.project = $this.$root.project;
			
		});
		
		this.$root.get_project_ready = true;
	},

	methods: {
		
		default_values: function(theme) {
		
			if(theme == 'dark') {
				
				this.project.css_background = '#1f1f1f';
				this.project.css_background_primary = '#292929';
				this.project.css_border_color = '#292929';
				this.project.css_color_nft_name = '#999999';
				this.project.css_color_countdown = '#bdbcbc';
				this.project.css_box_shadow_header = '#141414';
				
				// primary
				this.project.css_background_button_primary = '#5f4794';
				this.project.css_color_button_primary = '#ffffff';
				this.project.css_background_button_primary_hover = '#4f397f';
				this.project.css_color_button_primary_hover = '#ffffff';
				
				// secondary
				this.project.css_background_button_secondary = '#3a3a3a';
				this.project.css_color_button_secondary = '#838383';
				this.project.css_background_button_secondary_hover = '#4e4d4d';
				this.project.css_color_button_secondary_hover = '#afaeae';
				
				// misc
				this.project.css_background_button_back_to_raffle = '#000000';
				
				
			}
			else {
				
				this.project.css_background = '#ebebeb';
				this.project.css_background_primary = '#ffffff';
				this.project.css_border_color = '#ededed';
				this.project.css_color_nft_name = '#575757';
				
				this.project.css_color_countdown = '#5f4794';
				this.project.css_box_shadow_header = '#d1d1d1';
				
				// primary
				this.project.css_background_button_primary = '#5f4794';
				this.project.css_color_button_primary = '#ffffff';
				this.project.css_background_button_primary_hover = '#4f397f';
				this.project.css_color_button_primary_hover = '#ffffff';
				
				// secondary
				this.project.css_background_button_secondary = '#d1d1d1';
				this.project.css_color_button_secondary = '#939292';
				this.project.css_background_button_secondary_hover = '#c6c6c6';
				this.project.css_color_button_secondary_hover = '#747272';
				
				// misc
				this.project.css_background_button_back_to_raffle = '#000000';
			}
		},
		
		save_config: function() {
		
			var project = this.project;
			var $this = this;
			
			axios.post('https://raffle-back.effe2.xyz/raffle/save-project', {project}).then(function (result) {
				
				$this.$root.modal = {title: 'Perfect !', text: 'Configuration saved successfully'};
				$this.$root.$emit('openModal');
			});
		},
		
		
		find_pda_collection_owner: async function() {
			
			var pda = await find_pda_collection_owner();
			
			console.log(pda.toString());
		},
		
		create_collection: async function() {
			
			var wallet_provider = getSolanaObject();
			var wallet_address = getWalletAddress();
			
			this.find_pda_collection_owner();
			
			/*
			var collection_data = {
				creators: [
					{share: 80, verified: false, address: new PublicKey("8SZTojxgUPmKDkiBm4rg5K7tm6c1STLvn7L2a68jn9AA")},
					{share: 20, verified: false, address: new PublicKey("AuToz9gEG4f4fh67zo2EiwFKvW6tneMYE6UNDmX95nrL")}
				],
				metadata: {
					name: 'BONKS',
					symbol: 'BNKS',
					uri: 'https://cdn.effe2.xyz/storage/nft/bonks/collection.json',
					sellerFeeBasisPoints: 1000,
				}
			};
			
			var signature = await create_collection(wallet_provider, wallet_address, collection_data);
			
			console.log("signature collection", signature);
			var signature2 = await create_escrow(wallet_provider, wallet_address, 3333);
			
			console.log("signature escrow", signature2);
			*/
			
			/*
			var signature = await update_supply(wallet_provider, wallet_address, 1111, "GKfjV2yG8kSbRNouz1oK7jDNGvC2X7Vf1uSet7MuSF7Y");
			console.log("signature escrow", signature);
			var data = await check_escrow(wallet_provider, wallet_address, "GKfjV2yG8kSbRNouz1oK7jDNGvC2X7Vf1uSet7MuSF7Y");
			
			console.log('supply', data.supply.toString());
			console.log('currentId', data.currentId.toString());
			*/
			/*
			
			var signature = await create_escrow_wl_unlimited(wallet_provider, wallet_address, "8R1jQHRLdh9GorFkXcNam9RyJTVPMmmFuzuVZLWh3NxB");
			
			console.log(signature);
			// var data = await check_escrow_wl_unlimited(wallet_provider, wallet_address, "5BU2rVngj3ep34z3worjTPhCjZwFhkBXuqrr1iDYJwyb");
			
			// console.log(data);
			
			var signature2 = await create_escrow_wl_limited(wallet_provider, wallet_address, "8R1jQHRLdh9GorFkXcNam9RyJTVPMmmFuzuVZLWh3NxB", 2);
			console.log(signature2);
			
			// var data = await check_escrow_wl_limited(wallet_provider, wallet_address, "BPfdvm4kz3HqwuZKKV81NdYrUb4sqsdKb6WciJnG8vxE");
			
			// console.log(data);
			*/
			
			// var signature = await update_metadata(getSolanaObject(), "Gj2kZiX6av2DMrn4y4vWEc2uhTBMELHU4o6fmBHt6BF5");
			var escrows = await fetch_all_escrow_collection();
			
			console.log(escrows);
			
		},
		
		
		
		
		
	},
	watch:{}
}
</script>



